<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getDefaultQuotas } from './api'
import Notify from '@/notify'
import debounce from '@/utils/debounce'

const highlightOptionMap = {
  0: {
    label: 'Não',
    class: 'badge-soft-danger'
  },
  1: {
    label: 'Sim',
    class: 'badge-soft-success'
  }
}

export default {
  setup () {
    const contestDefaultQuotasList = ref([])
    const filter = ref({
      highlightOption: null,
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchDefaultQuotas()
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchDefaultQuotas = debounce(() => {
      const params = {
        status: filter.value.status,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        confirmed_at: {
          gte: dateToISO(filter.value.confirmed_at.from),
          lte: dateToISO(filter.value.confirmed_at.to)
        },
        order: {
          created_at: 'desc'
        }
      }

      getDefaultQuotas(params).then((data) => {
        console.log(data)
        contestDefaultQuotasList.value = data
      }).catch((e) => {
        console.log(e)
        Notify.create({
          message: "houve um problema"
        })
      })
    }, 1000)

    watch(filter, () => {
      fetchDefaultQuotas()
    }, { deep: true })

    const highlightOptionGet = (highlightOption, key) => {
      return highlightOptionMap[highlightOption][key]
    }

    return {
      contestDefaultQuotasList,
      fetchDefaultQuotas,
      highlightOptionGet,
      filter,
      highlightOptionMap
    }
  },
  components: {
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Cotas padrão de sorteio'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      ID
                    </th>
                    <th scope="col">
                      Valor
                    </th>
                    <th scope="col">
                      Opção em destaque
                    </th>
                    <th scope="col">
                      Criado em
                    </th>
                    <th scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contestDefaultQuota in contestDefaultQuotasList"
                    :key="contestDefaultQuota.id"
                  >
                    <td>{{ contestDefaultQuota.id }}</td>
                    <td>{{ contestDefaultQuota.value }}</td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="highlightOptionGet(contestDefaultQuota.highlight_option, 'class')"
                      >
                        {{ highlightOptionGet(contestDefaultQuota.highlight_option, 'label') }}
                      </span>
                    </td>
                    <td>{{ contestDefaultQuota.created_at? $d(contestDefaultQuota.created_at, 'info') : '' }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'contest_default_quotas_edit', params: { id: contestDefaultQuota.id } }"
                      >
                        <a
                          data-bs-placement="top"
                          title="Editar"
                          class="px-2 text-primary"
                        ><i class="bx bx-pencil font-size-18" /></a>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
